import i18n from '@/i18n';
import api from "@/services/api";
import _ from "lodash";
import configResource from "@/services/resources/config";
import {setMomentConfiguration} from '@/services/moment'

/**
 * Este modulo se encarga de gestionar la confuguración del usuario
 */

const defaultConfig = {
  regional: {},
  language: {},
};

const defaultState = () => {
  let config = JSON.parse(localStorage.getItem("config"));

  return {
    regional: (config && config.regional) || defaultConfig.regional,
    language: (config && config.language) || defaultConfig.language
  };
};

const loadAccountConfiguration = commit => {
  return api.get("my-account/configuration").then(response => {
    commit("setBackendConfig", response.data);
    return response.data;
  });
};

const getLanguageKeys = () => {
  const languages = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return languages.keys().map(key => {
    const languageKey = key.match(/([A-Za-z0-9-_]+)\./i)
    return languageKey && languageKey.length > 1 ? languageKey[0] : '';
  })
}

const getValidOrDefaultLanguage = (state, language = null) => {
  const languageKeys = getLanguageKeys();
  language = language || state.language.language || '';

  if (language in languageKeys) {
    return language;
  }

  const languageRoot = language.split('-').shift();
  switch (languageRoot) {
    case 'es':
      return 'es-PE';
    case 'en':
      return 'en-US';

    default:
      return 'es-PE';
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getConfig: state => state
  },
  actions: {
    saveConfig({ commit }, data) {
      return configResource.saveAllPersonalConfig(data).then(() => {
        commit("setBackendConfig", data);
      });
    },
    verifyConfig({ commit }) {
      let config = JSON.parse(localStorage.getItem("config"));
      let isLoggedIn = !!localStorage.getItem("token");

      if (_.isEmpty(config) && isLoggedIn) {
        return loadAccountConfiguration(commit);
      }
      return true;
    },
    getConfig({ commit }) {
      return loadAccountConfiguration(commit);
    },
    changeLang({ commit }, language = null) {
      commit('setLang', language)
    },
    resetState({ commit }) {
      commit("resetState");
    }
  },
  //cambiar el valor de un estado
  mutations: {
    setConfig(state, { type, data }) {
      state[type] = data;

      let config = JSON.parse(localStorage.getItem("config")) || {};
      config[type] = data;
      i18n.locale = state.language.language;
      localStorage.setItem("config", JSON.stringify(config));
      window.location.reload();
    },
    setBackendConfig(state, config) {
      state.regional = config.regional;
      state.language = config.language;
      i18n.locale = config.language.language;
      localStorage.setItem("config", JSON.stringify(config));
      setMomentConfiguration(config)
    },
    resetState(state) {
      localStorage.removeItem("config");
      Object.assign(state, defaultState());
    },
    setLang(state, language = null) {
      language = getValidOrDefaultLanguage(state, language);
      state.language = language;
      i18n.locale = language;
    }
  }
};

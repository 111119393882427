import Vue from "vue";

Vue.filter('formatDate', function (value) {
  if (value) {
    let date = moment(String(value));
    if (date.isValid()) {
      return date.format('DD/MM/YYYY')
    }
    return ""
  }
});

Vue.filter('formatDateRange', function (value) {
  let result = '';
  if (value && Array.isArray(value)) {
    result = value?.map(date => {
      let momentDate = moment(String(date));
      if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY')
      }
    }).join(' - ');
  }
  return result;
});

Vue.filter('formatHour', function (value) {
  if (value) {
    let hour = moment(String(value))
    if (hour.isValid()) {
      return hour.format('HH:mm')
    }
    return ""
  }
})

Vue.filter('formatHourRange', function (value) {
  let result = '';
  if (value && Array.isArray(value)) {
    result = value?.map(date => {
      let momentHour = moment(String(date))
      if (momentHour.isValid()) {
        return momentHour.format('HH:mm')
      }
    }).join(' - ');
    return result;
  }
})

Vue.filter('formatDay', function (value) {
  if (value) {
    let date = moment(String(value));
    if (date.isValid()) {
      return date.format('DD')
    }
    return ""
  }
});

Vue.filter('formatDayRange', function (value) {
  let result = '';
  if (value) {
    result = value.map(date => {
      let momentDate = moment(String(date));
      if (momentDate.isValid()) {
        return momentDate.format('DD')
      }
    }).join(' - ');
    return result;
  }
});


Vue.filter('formatMonth', function (value) {
  if (value) {
    let date = moment(String(value));
    if (date.isValid()) {
      return date.format('MM')
    }
    return ""
  }
});

Vue.filter('formatMonthRange', function (value) {
  let result = '';
  if (value) {
    result = value.map(date => {
      let momentDate = moment(String(date));
      if (momentDate.isValid()) {
        return momentDate.format('MM');
      }
    }).join(' - ');

    return result;
  }
});

Vue.filter('formatMoney', function (value) {
  if(Number.isNaN(value)) return ''

  try {
    let result = Number(value/100).toFixed(2);
    if(Number.isNaN(result)) return ''
    return result
  } catch (error) {
    console.error('formatMoney error', {error})
    return ""
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    let hour = moment(String(value))
    if (hour.isValid()) {
      return hour.format('DD/MM/YYYY HH:mm:ss')
    }
    return "-"
  }
})

Vue.filter('formatDateTimeRange', function (value) {
  let result = '';
  if (value) {
    result = value.map(date => {
      let momentDate = moment(String(date));
      if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY HH:mm:ss')
      }
    }).join(' - ');
    return result;
  }
})

Vue.filter('formatBool', function (value) {
  if(value === null) return '--'
  return value == "1"
    ? "SI"
    : "NO"
})

Vue.filter('shortDateTime', function (value) {
  if (value) {
    let hour = moment(String(value))
    if (hour.isValid()) {
      return hour.format('DD/MM/YY HH:mm')
    }
    return ""
  }
})

Vue.filter('unixFormatDateTime', function (value) {
  if (value) {
    let hour = moment.unix(value)
    if (hour.isValid()) {
      return hour.format('DD/MM/YYYY HH:mm:ss')
    }
    return ""
  }
})

Vue.filter('diffFromNow', function (value) {
  if (value) {
    const date = moment(String(value))
    const diffInHours = Math.abs(date.diff(moment(), 'hours'))
    if (diffInHours == 0) {
      const diffInMinutes = Math.abs(date.diff(moment(), 'minutes'))
      return diffInMinutes + "m"
    }
    return diffInHours + "h"
  }
})

Vue.filter('metersToKm', function (value) {
  if (value && _.isNumber(value)) {
    const km = parseInt(value/1000);
    const mt = value - (km * 1000);
    return km + "Km " + (mt > 0 ? mt+"mt": '');
  }
  return ""
})

Vue.filter('formatMinutesHumanize', function (value) {
  if (value) {
    return moment.duration(value, 'minutes').locale('es').humanize()
  }
  return ''
})

Vue.filter('formatMinutes', function (value) {
  if (value) {
    return moment.duration(value, 'minutes').format('d [d] h [hrs] m [min]')
  }
  return ''
})

Vue.filter('floatTwoDecimals', function (value) {
  if (value) {
    return value.toFixed(2);
  }
  return value;
})

Vue.filter('floor', function (value) {
  if (value) {
    return Math.floor(value);
  }
  return value;
})

Vue.filter('unescape', function (value) {
  return unescape(value);
})

Vue.filter('lower', function (value) {
  return value.toLowerCase()
})

Vue.filter('moneyFormat', function (value) {
  let amount = value/100
  return Number(amount).toFixed(2)
})

/**
 * Cast user time HH:mm to UTC
 */
Vue.filter("castTimeToUtc", function(time) {
  return moment(time, "HH:mm", true)
    .utc()
    .format("HH:mm");
});

/**
 * Cast UTC time to user time
 */
Vue.filter("castUtcToUserTime", function(time) {
  let timezone = moment.getConfiguration().timezone
  return moment.utc(time,'HH:mm').tz(timezone).format('HH:mm')
});

import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/factoring',
      name: 'Principal',
      component: () => import('@/containers/DefaultContainer'),
      children: [
        {
          path: 'monitor',
          name: 'monitor',
          component: () => import('@/pages/Monitor/Index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/pages/Dashboard/Index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'clients/:type',
          name: 'clients',
          component: () => import('@/pages/Clients/Index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'factoring',
          name: 'factoring',
          component: () => import('@/pages/Factoring/Index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'document-requirements',
          name: 'document-requirements',
          component: () => import('@/pages/DocumentRequirements/Index'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/system/',
          name: 'Sistema',
          redirect: 'system/roles',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'roles',
              name: 'Lista de Roles',
              component: () => import('@/pages/Roles/Roles'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'users',
              name: 'Usuarios',
              component: () => import('@/pages/Users/Users'),
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'roles/:id/permissions',
              name: 'Permisos de Rol',
              component: () => import('@/pages/Roles/RolesPermissions'),
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'config/personal',
              name: 'Configuración Personal',
              props: {
                editGlobal: false
              },
              component: () => import('@/pages/SystemConfig/SystemConfig'),
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'config/general',
              name: 'Configuración General',
              component: () => import('@/pages/SystemConfig/SystemConfig'),
              props: {
                editGlobal: true
              },
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: '/reports/',
          redirect: '/reports/background-reports',
          name: 'Reportes',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'background-reports',
              name: 'background-reports',
              component: () => import('@/pages/Reports/BackgroundReports'),
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/Login/Login'),
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/pages/Login/ForgotPassword'),
    },
    {
      path: '/restore-password/:token',
      name: 'RestorePassword',
      component: () => import('@/pages/Login/RestorePassword'),
    },
    {
      path: '/error',
      redirect: '/error/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: () => import('@/pages/Errors/Page404')
        },
        {
          path: '500',
          name: 'Page500',
          component: () => import('@/pages/Errors/Page500')
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  let isAuth = store.getters['user/isLoggedIn'];
  let requiresAuth = to.meta.requiresAuth || false;

  store.commit('loading');
  store.dispatch('config/changeLang');

  if (requiresAuth && !isAuth) {
    store.commit('endLoading');
    next('/login');
  }
  next();
});

router.afterEach(() => {
  store.commit('endLoading');
});


export default router;

import moment from "moment-timezone";

const DEFAULT_LANGUAGE = "es";
const DEFAULT_TIMEZONE = "America/Lima";

async function getDefaultTimeZone() {
  try {
    let response = await api.get('version');
    let timezone = response.data.timezone;
    return timezone;
  } catch (error) {
    console.log(error);
  }
}

const _moment = moment

_moment.getConfiguration = function () {
  return { language: _moment.locale(), timezone: _moment.defaultZone.name };
};

export const setMomentConfiguration = async function (configuration) {
  let language = configuration?.language?.language?.split('-')?.[0] ?? DEFAULT_LANGUAGE;
  let timezone = configuration?.regional?.timezone ?? await getDefaultTimeZone() ??  DEFAULT_TIMEZONE
  _moment.locale(language);
  _moment.tz.setDefault(timezone);

  return _moment;
}
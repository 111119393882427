window.sgConfirm = (title, msg, button, success, type, preConfirm) => {
  let conf = {
    title: title,
    html: msg,
    icon: type == null ? "info" : type,
  };
  if (button != "noButton") {
    conf = {
      ...conf,
      showCancelButton: true,
      confirmButtonText: button == null ? "Aceptar" : button,
    };
  }
  if (preConfirm) {
    conf = {
      ...conf,
      preConfirm: preConfirm,
      input: "text",
    };
  }
  Swal.fire(conf).then(function (result) {
    if (result.value) {
      success();
    }
  });
};

window.sgProp = (title, confirmButton, success) => {
  Swal.fire({
    title: title,
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: confirmButton,
  }).then((result) => {
    if (result.isConfirmed) {
      success(result.value);
    }
  });
};

window.asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

window.asyncMap = async function (array, callback) {
  let newArray = [];
  for (let index = 0; index < array.length; index++) {
    newArray[index] = await callback(array[index], index, array);
  }
  return newArray;
};

window.sleep = function (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

window.downloadAjaxFile = function (filename, url, method, data) {
  method = method || "get";
  data = data || null;
  return api({
    url: url,
    method: method,
    data: data,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  });
};

window.cutForPage = function (data, currentPage, perPage) {
  return data.slice((currentPage - 1) * perPage, currentPage * perPage);
};

window.cleanReferences = function (data) {
  return JSON.parse(JSON.stringify(data));
};

window.haveLatLng = function (obj, prefix = "") {
  return (
    obj?.[`${prefix !== "" ? prefix + "_" : ""}lat`] &&
    obj?.[`${prefix !== "" ? prefix + "_" : ""}lng`]
  );
};

window.getLatLng = function (obj, prefix = "") {
  if (!window.haveLatLng(obj, prefix)) return null;
  return {
    lat: parseFloat(obj?.[`${prefix !== "" ? prefix + "_" : ""}lat`]),
    lng: parseFloat(obj?.[`${prefix !== "" ? prefix + "_" : ""}lng`]),
  };
};

window.hasIn = function(object, path) {
  return window._.hasIn(object, path);
};

window.haveLatLng = function (obj, prefix = "") {
  return (
    obj?.[`${prefix !== "" ? prefix + "_" : ""}lat`] &&
    obj?.[`${prefix !== "" ? prefix + "_" : ""}lng`]
  );
};
window.getLatLng = function (obj, prefix = "") {
  if (!window.haveLatLng(obj, prefix)) return null;
  return {
    lat: parseFloat(obj?.[`${prefix !== "" ? prefix + "_" : ""}lat`]),
    lng: parseFloat(obj?.[`${prefix !== "" ? prefix + "_" : ""}lng`]),
  };
};

window.getPropValue = function (object, path = '', defaultReturn = null) {
  return path.split('.')
      .reduce((o, x) => o == undefined ? defaultReturn : o[x]
      , object)
}

window.cleanReferences = function (data) {
  return JSON.parse(JSON.stringify(data));
};

window.clearInputsForm = function (data) {
  Object.entries(data).forEach(([key]) => {
    if (toType(data[key]) === "object") {
      Object.entries(data[key]).forEach(([key2]) => {
        return data[key][key2] = null;
      });
    } else {
      return data[key] = null;
    }
  });
};

window.toType = function (data) {
  return {}.toString
    .call(data)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();
};

window.schemaFormToObject = function (schemas) {
  let mapInputs = schemas.flatMap((schema) => schema.inputs)
  let obj = {}

  mapInputs.forEach(input => {
    Object.assign(obj, {[input.bind]:''})
  })
  return obj
};
export const monitorPermissions = {
  'read': 'read monitor',
}

export const dashboardPermissions = {
  'show': 'show dashboard'
}

export const clientPermissions = {
  'create': 'create client',
  'read': 'read client',
  'update': 'update client',
  'createFinancialCompany': 'create financial company client',
  'readFinancialCompany': 'read financial company client',
  'updateFinancialCompany': 'update financial company client'
}

export const userPermissions = {
  'create': 'create user',
  'read': 'read user',
  'update': 'update user',
  'delete': 'delete user',
  'export': 'export user',
}

export const rolePermissions = {
  'create': 'create role',
  'read': 'read role',
  'update': 'update role',
  'delete': 'delete role'
}

export const invoicePermissions = {
  'read': 'read invoice',
  'approve': 'approve invoice',
  'reject': 'reject invoice',
  'review': 'review invoice',
  'observed': 'observed invoice',
  'markBeneficiary': 'mark beneficiary paid invoice',
  'markDebtorPaidInvoice': 'mark debtor paid invoice'
}

export const configurationPermissions = {
  'read': 'read default configuration',
  'update': 'update default configuration'
}

export const creditLinesPermissions = {
  'create': 'create credit line',
  'read': 'read credit line',
  'update': 'update credit line',
  'inactivate': 'inactivate credit line',
  'activate': 'activate credit line',
  'financialCompanyCreate': 'create financial company credit line',
  'financialCompanyRead': 'read financial company credit line',
  'financialCompanyUpdate': 'update financial company credit line',
  'financialCompanyInactivate': 'inactivate financial company credit line',
  'financialCompanyActivate': 'activate financial company credit line',
}

export const backgroundReportPermissions = {
  'create' : 'create background report',
  'read'   : 'read background report',
  'readAll': 'read all users background report',
}

export const documentRequirementPermissions = {
  'create': 'create requirement document',
  'read'  : 'read requirement document',
  'update': 'update requirement document',
}

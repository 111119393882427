import i18n from "@/i18n";
export default {
  global: "my-account/default/configuration",
  personal: "my-account/configuration",
  getGlobalConfig() {
    return api.get(this.global).then(response => {
      return response.data;
    });
  },
  getGlobalConfigByType(type) {
    return api.get(`${this.global}/${type}`).then(response => {
      return response.data;
    });
  },
  saveGlobalConfig({ type, data }) {
    return api.post(`${this.global}/${type}`, data).then(response => {
      Toast.fire(
        i18n.t("general.messages.congratulations"),
        i18n.tc("general.messages.register-successful", 1),
        "success"
      );
      return response;
    });
  },
  saveAllPersonalConfig(data) {
    return api.post(this.personal, data)
      .then(response => {
        Toast.fire(
          i18n.t("general.messages.congratulations"),
          i18n.tc("general.messages.register-successful", 1),
          "success"
        );
        return response;
      });
  },
  saveAllGlobalConfig(data) {
    return api.post(this.global, data)
      .then(response => {
        Toast.fire(
          i18n.t("general.messages.congratulations"),
          i18n.tc("general.messages.register-successful", 1),
          "success"
        );
        return response;
      });
  },
  getPersonalConfig() {
    return api.get(this.personal).then(response => {
      return response.data;
    });
  },
  savePersonalConfig({ type, data }) {
    return api.post(`${this.personal}/${type}`, data).then(response => {
      Toast.fire(
          i18n.t("general.messages.congratulations"),
          i18n.tc("general.messages.register-successful", 1),
          "success"
      );
      return response;
    });
  },
};

import axios from "axios";
import store from "../store";
import router from "../router";
import i18n from '@/i18n'

const CancelToken = axios.CancelToken;

window.API_URL = process.env.VUE_APP_API_URL || window.API_URL;

const axiosInstance = axios.create({
  baseURL: window.API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  if (!navigator.onLine) {
    return {
      ...config,
      cancelToken: new CancelToken((cancel) => cancel(i18n.t('general.messages.network-offline')))
    };
  }

  config.headers.Authorization =  'Bearer ' + store.getters['user/getToken'];

  const language = store.getters["config/getConfig"].language.language ?? navigator.language;

  config.headers['Accept-Language'] = language;

  if(!config.noloading) {
    store.commit('loading');
  }

  return config;
}, (error) => {
  store.commit('endLoading');
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  if(!response.config.noloading) {
    store.commit('endLoading');
  }
  return response;
}, function (error) {
  console.log('Ocurrio un error', error.response);
  if (axios.isCancel(error)) {
    console.log('Ocurrio una cancelacion');
    Toast.fire('Error', error.message, 'error');
    store.commit('endLoading');
    return Promise.reject(error.message);
  }

  let json = error.response.data;
  let message = json.message;

  store.commit('endLoading');

  //If error 401 redirect to login
  if(error.response.status === 401 && router.currentRoute.name !== 'login') {
    router.push('/login');
  } else if(error.response.status === 404) {
    message = 'El recurso no existe';
  }

  if(error?.config?.showErrors !== false){
    if(json.errors !== undefined) {
      let errors = [];
      _.forEach(json.errors, (err, index) => {
        errors.push(err[0]);
      });

      Toast.fire('Error', errors.join('<br>'), 'error');
    } else {
      console.log(message, json);
      Toast.fire('Error', message, 'error');
    }
  }

  return Promise.reject(error.response);
});

export default axiosInstance;

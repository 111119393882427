import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import user from "./modules/user";
import invoiceTrends from "./modules/invoiceTrends";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const ossAdminVersion = process.env.VUE_APP_OSS_ADMIN_VERSION || "1.0";
const ossApiVersion = "";

export default new Vuex.Store({
  modules: { config, user, invoiceTrends},
  strict: debug,
  state: {
    loadingCount: 0,
    version: ossAdminVersion,
    apiVersion: ossApiVersion,
    featureFlags: [],
  },
  getters: {
    featureActive: (state) => (featureFlag) => {
      return state.featureFlags.includes(featureFlag);
    },
  },
  mutations: {
    loading(state) {
      state.loadingCount++;
    },
    endLoading(state) {
      if (state.loadingCount > 0) {
        state.loadingCount--;
      }
    },
    setApiVersion(state, apiVersion) {
      state.apiVersion = apiVersion;
    },
    setFeatureFlags(state, flags) {
      state.featureFlags = flags;
    },
  },
  actions: {
    loadApiVersion({ commit }) {
      return api.get("version").then(({ data }) => {
        console.log(data);
        commit("setApiVersion", data.version);
        commit("setFeatureFlags", data.flags);
      });
    },
  },
});

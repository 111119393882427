import Vue from "vue";
import store from "@/store";

export default {
  // Se llama solo una vez, cuando la directiva se enlaza por primera vez con el elemento.
  bind: function (el, binding, vnode) {
    Vue.nextTick(() => {
      // vnode.context.$store.getters.hasApiPermission(binding.value)
      if (!store.getters['user/userCan'](binding.value)) {
        el.parentNode.removeChild(el);
      }
    });
  }
};

import { openDB } from 'idb';

const factoringStore = openDB('oss', 1, {
  upgrade(db) {
    db.createObjectStore('invoiceTrends');
  },
});

export async function get(key) {
  return (await factoringStore).get('invoiceTrends', key);
}

export async function set(key, val) {
  return (await factoringStore).put('invoiceTrends', val, key);
}

export async function keys() {
  return (await factoringStore).getAllKeys('invoiceTrends');
}

export async function getAll() {
  return (await factoringStore).getAll('invoiceTrends');
}

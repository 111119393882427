import store from "@/store";

import {
  monitorPermissions,
  clientPermissions,
  userPermissions,
  rolePermissions,
  invoicePermissions,
  configurationPermissions,
  backgroundReportPermissions,
  documentRequirementPermissions,
  dashboardPermissions
} from '@/shared/permissions';

import {
  features
} from '@/shared/features';

const items = [
  {
    name: 'nav.dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    attributes: {
      visible: store.getters['user/userCan'](dashboardPermissions.show)
    }
  },
  {
    name: 'nav.clients-debtor',
    url: '/clients/debtor',
    icon: 'fa fa-handshake-o',
    attributes: {
      visible: store.getters['user/userCan'](clientPermissions.read) || store.getters['user/userCan'](clientPermissions.readFinancialCompany),
    }
  },
  {
    name: 'nav.clients-beneficiary',
    url: '/clients/beneficiary',
    icon: 'fa fa-users',
    attributes: {
      visible: store.getters['user/userCan'](clientPermissions.read),
    }
  },
  {
    name: 'nav.factoring',
    url: '/factoring',
    icon: 'fa fa-money',
    attributes: {
      visible: store.getters['user/userCan'](invoicePermissions.read),
    }
  },
  {
    name: 'nav.document-requirements',
    url: '/document-requirements',
    icon: 'fa fa-handshake-o',
    attributes: {
      visible: store.getters['user/userCan'](documentRequirementPermissions.read),
    }
  },
  {
    name: 'nav.system.title',
    icon: 'fa fa-cog',
    url: '/system/',
    attributes: {
      visible: store.getters['user/userCan'](rolePermissions.read)
        || store.getters['user/userCan'](userPermissions.read)
    },
    children: [
      {
        name: 'nav.system.roles',
        url: '/system/roles',
        icon: 'fa fa-shield',
        attributes: {
          visible: store.getters['user/userCan'](rolePermissions.read)
        }
      },
      {
        name: 'nav.system.users',
        url: '/system/users',
        icon: 'fa fa-users',
        attributes: {
          visible: store.getters['user/userCan'](userPermissions.read)
        }
      },
      {
        name: 'nav.system.config.title',
        url: '/system/config',
        icon: 'fa fa-cog',
        attributes: {
          visible: store.getters['user/userCan'](configurationPermissions.update)
        },
        children: [
          {
            name: 'nav.system.config.general',
            url: '/system/config/general',
            icon: 'fa fa-globe',
            attributes: {
              visible: store.getters['user/userCan'](configurationPermissions.update)
            },
          }
        ]
      },
    ]
  },
  {
    name: 'nav.reports.title',
    icon: 'fa fa-list',
    url: '/reports/',
    attributes: {
      visible: store.getters['user/userCan'](backgroundReportPermissions.read)
        || store.getters['user/userCan'](backgroundReportPermissions.readAll)
    },
    children: [
      {
        name: 'nav.reports.background-reports',
        url: '/reports/background-reports',
        icon: 'fa fa-files-o',
        attributes: {
          visible: store.getters['user/userCan'](backgroundReportPermissions.read) ||
            store.getters['user/userCan'](backgroundReportPermissions.readAll)
        }
      }
    ]
  },
];

function visibleItemsFn(items) {
  let visibleItems = [];
  items.forEach(item => {
    if (item.attributes !== undefined && item.attributes.visible) {
      if(item.children !== undefined && item.children.length > 0) {
        item.children = visibleItemsFn(item.children);
      }
      visibleItems.push(item);
    }
  });
  return visibleItems;
}

export default {
  items: visibleItemsFn(items)
}

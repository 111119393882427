// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
window.SERVICE_NAME = "testing";
if (!process.env.VUE_APP_API_URL) {
  window.SERVICE_NAME = window.subdomain;
}

import BootstrapVue from "bootstrap-vue";
import "core-js/actual/promise";
import "core-js/actual/string";
import "core-js/actual/array";
import "core-js/actual/object/define-properties";
import Vue from "vue";
import VueHighlightJS from "vue-highlightjs";
import JsonExcel from "vue-json-excel";
import "vue-swatches/dist/vue-swatches.css";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import Paginate from "vuejs-paginate";
import App from "./App";
import router from "./router";
import api from "./services/api";
import { setMomentConfiguration } from "./services/moment";
import store from "./store";
import Vuelidate from 'vuelidate';

import VueLayers, { BingmapsSource } from "vuelayers";

import 'vuelayers/lib/style.css';
import i18n from './i18n';


require("./defaultComponents");

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BingmapsSource);
Vue.component("DownloadExcel", JsonExcel);
Vue.use(VueHighlightJS);
Vue.use(VueLayers, {
  dataProjection: "EPSG:4326"
});

Vue.config.productionTip = false;

window.Swal = require("sweetalert2");

window.momentDurationFormatSetup = require("moment-duration-format");

const Toast = (window.Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 4000
}));

window.$ = window.jQuery = require("jquery");

window.api = api;
//window.resources = require('./services/resources');
window._ = require("lodash");

require("./helpers");
require("bootstrap");

import directiveUserCan from "./directives/directiveUserCan";
Vue.directive('can', directiveUserCan)

store.dispatch("config/verifyConfig").then(async () => {
  const configuration = store.getters['config/getConfig']
  window.moment = await setMomentConfiguration(configuration);
  new Vue({
    el: "#app",
    router,
    components: {
      App
    },
    async beforeCreate(){
      require("./filters");
    },
    template: "<App/>",
    store,
    i18n
  });

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      libraries: "places" // This is required if you use the Autocomplete plugin
    },
    installComponents: true
  });

  Vue.component("GmapCluster", GmapCluster);
  Vue.component("Paginate", Paginate);
});

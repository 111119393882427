export default {
  root_historic: 'kpis-historic',
  root_kpis: 'kpis',
  getHistorics(type, params = {}){
    return api.get(`${this.root_historic}/${type}`, {params/* , noloading: true */});    
  },
  getInvoicesTrend(params = {}){
    return api.get(`${this.root_kpis}/invoices-trend`, { params });
  },
}

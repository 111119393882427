import {get, set, keys, getAll } from '@/services/idbInvoiceTrends';
import kpiService from "@/services/resources/kpis";

const defaultState = () => {
  return {
    invoiceTrends: []
  }
};

export default {
  namespaced: true,
  state: defaultState(),
  actions: {
    async getInvoiceTrendsFromCache({ state }, date) {
      // Verificar si los datos de la fecha ya están en el estado.
      const existingData = state.invoiceTrends.find(item => item.date === date);
      
      if (existingData) {
        return existingData.data;
      }
  
      // Si no, verificar si los datos de la fecha ya están en IDB.
      const cachedData = await get(date);
  
      if (cachedData) {
        return cachedData;
      }
  
      // Si no, devolver null.
      return null;
    },
    async cacheData({ commit }, { date, data }) {
      // Guardar los datos en IDB.
      await set(date, data);
      // Agregar los datos al estado.
      commit('ADD_DATA', { date, data });
    },
  },
  mutations: {
    ADD_DATA(state, { date, data }) {
      state.invoiceTrends.push({ date, data });
    },
  }
}

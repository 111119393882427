import router from "../../router";
import api from "@/services/api";

const defaultUser = {
  first_name: "",
  is_super_admin: false
};

const defaultState = () => {
  return {
    user: JSON.parse(localStorage.getItem("user")) || defaultUser,
    token: localStorage.getItem("token") || "",
    permissions: JSON.parse(localStorage.getItem("permissions")) || []
  };
};

const permissionsRoutesAvailable = [
  { path: "/tms/monitor-dashboard", permission: "show tracking" },
  { path: "/clients", permission: "read client" },
  { path: "/reports/orders", permission: "read report orders" },
  { path: "/reports/map-tracker", permission: "read report map" },
  { path: "/tms/monitor-dashboard", permission: "show tracking" },
  { path: "/tms/work-flows", permission: "read workflow" },
  { path: "/tms/planning", permission: "read driver" },
  { path: "/tms/map", permission: "read driver" },
  { path: "/tms/orders", permission: "read order only of my responsibility" },
  { path: "/tms/locations", permission: "read location" },
  { path: "/tms/zones", permission: "read location" },
  { path: "/tms/providers", permission: "read provider" }
];
export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getUser: state => state.user,
    getToken: state => state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    permissions: state => state.permissions,
    isSuperAdmin: state => state.user.is_super_admin,
    userCan: (state, getters) => permission => {
      if (getters.isSuperAdmin) {
        return true;
      }
      return state.permissions.includes(permission);
    }
  },
  actions: {
    login({ commit, dispatch }, data) {
      return api.post("login", data).then(r => {
        commit("setToken", r.data.token);
        commit("setUser", r.data.user);
        commit("setPermissions", r.data.permissions);
        dispatch("config/getConfig", null, { root: true });
        dispatch("redirectToPanel");
      });
    },
    logout({ commit, dispatch }) {
      api
        .post("logout", null, {
          headers: {
            Authorization: "Bearer " + this.getters["user/getToken"]
          }
        })
        .then(response => {
          commit("resetState");
          dispatch("config/resetState", null, { root: true });
          router.push("/login");
        });
    },
    resetState({ commit }) {
      commit("resetState");
    },
    email_recovery({ commit, dispatch }, data) {
      return api.post("forgot-password", data).then(response => {
        Toast.fire("Felicitaciones", response.data.message, "success");
      });
    },
    find_token({ commit, dispatch }, token) {
      return api.get(`find-token/${token}`).then(response => {
        return response.data;
      });
    },
    restore_password({ commit, dispatch }, data) {
      return api.post("restore-password", data).then(response => {
        Toast.fire("Felicitaciones", response.data.message, "success");
        router.push("/login");
      });
    },
    forChilds({ state, commit, dispatch }, data) {
      for (const element of data) {
        if (!element.attributes.hidden) {
          router.push(element.url);
          state.isRedirectedChild = true;
          break;
        }
        if (state.isRedirectedChild) {
          break;
        }
      }
    },
    redirectToPanel({ state, commit, dispatch }, data) {
      let nav = require("@/_nav");
      state.isRedirected = false;
      for (const element of nav.default.items) {
        if (!element.attributes.hidden) {
          if (element.children) {
            dispatch("forChilds", element.children);
          }
          if (!element.children) {
            state.isRedirectedChild = true;
            router.push(element.url);
          }
          if (state.isRedirectedChild) {
            break;
          }
        }
        if (state.isRedirectedChild) {
          break;
        }
      }
      // for (let i = 0; i < permissionsRoutesAvailable.length; i++) {

      //   if (this.getters['user/userCan'](permissionsRoutesAvailable[i].permission)) {
      //     router.push(permissionsRoutesAvailable[i].path);
      //     break;
      //   }
      // }
    }
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    setPermissions(state, permissions) {
      localStorage.setItem("permissions", JSON.stringify(permissions));
      state.permissions = permissions;
    },
    resetState(state) {
      localStorage.removeItem("token");
      Object.assign(state, defaultState());
    }
  }
};
